@import url("https://fonts.googleapis.com/css2?family=Oswald&display=swap");
@import "~react-image-gallery/styles/scss/image-gallery.scss";

* {
  box-sizing: border-box;
}

.overlay {
  min-height: 100vh;
  background-color: hsla(220, 13%, 18%, 0.8);
  z-index: 10;
}

.app-container {
  text-align: left;
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  // align-items: top;
  // background-color: black;
  justify-content: flex-start;
  font-family: "Oswald", "Helvetica Neue", Helvetica, Arial;
  font-size: calc(15px + 2vmin);
  color: white;
  padding: 1rem;
}

.app-container > p {
  font-size: calc(15px + 3vmin);
}

.text-container {
  text-align: center;
  padding: 0;
}

a {
  color: #ffd21d;
  text-decoration: none;
}

.header-container {
  background-image: url("https://getjohnnyjazz.com/resources/header-mobile.png");
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: contain;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-height: 15vh;
  width: "100%";
  border-bottom: 2px solid #ffd21d;
  margin-bottom: 1rem;
}

.navbar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-right: 0.2rem;
  margin-bottom: 1rem;
}

.header-title {
  padding-right: 0.5rem;
}

.title-icon {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.player-icon {
  position: absolute;
  // justify-content: flex-end;
  left: 0.5rem;
  align-items: center;
  color: hsl(48, 100%, 56%);
  animation-name: throb;
  animation-duration: 1200ms;
  animation-iteration-count: infinite;
}

.affiliates-block {
  // margin-top: 2rem;
  // mix-blend-mode: overlay;
  background-color: hsl(48, 100%, 56%);
  width: 2rem;
  height: 8rem;
  position: absolute;
  padding: 1rem;
}

.affiliates-img {
  mix-blend-mode: normal;
  // width: 3rem;
  // height: auto;
  padding: 0.5rem;
  border-radius: 10%;
}

.affiliates > h3 {
  font-family: "Oswald", "Helvetica Neue", Helvetica, Arial;
  font-size: 2rem;
  text-align: center;
  margin: 0;
  color: hsl(0, 0%, 100%, 100%);
}

.video {
  position: fixed;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  color: #fff;
  z-index: -1;
}

.insta-container {
  height: 100vh;
  border: 1px solid #ffd21d;
  border-radius: 5px;
  background-color: hsla(220, 220, 220, 1);
}

.insta-container::-webkit-scrollbar {
  display: none;
}

.footer-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: right;
  border: 1px solid red;
}

.footer {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  padding: 1rem;
  color: white;
  font-size: calc(10px + 2vmin);
}

.social-icons-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #ffd21d;
  padding: 1rem 0 0 0;
  border-top: 2px solid #ffd21d;
  font-size: 0.9rem;
}

.social-icons:hover {
  color: white;
}

.cta-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Oswald", "Helvetica Neue", Helvetica, Arial;
  font-size: calc(10px + 2vmin);
  padding: 1rem;
  color: #ffd21d;
}

.contact-icons-container {
  display: flex;
  padding: 1rem;
  // border-radius: 0.5rem;
  border-top: 1px solid #ffd21d;
}

.contact-icon-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.contact-icon-container:hover {
  color: white;
}
.contact-icon:hover {
  color: white;
}

.bouncer {
  display: flex;
  flex-direction: column;
  font-size: 2rem;
  align-items: center;
  animation-duration: 1200ms;
  animation-name: bouncer;
  animation-iteration-count: infinite;
}

@keyframes bouncer {
  0% {
    transform: translateY(25%);
  }
  50% {
    transform: translateY(50%);
  }

  100% {
    transform: translateY(25%);
  }
}

@keyframes throb {
  0% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(1);
  }
}

// media queries

@media (min-width: 802px) {
  .text-container {
    text-align: center;
  }

  // .affiliates-img {
  //   width: 8rem;
  //   height: auto;
  //   padding: 0rem 0.5rem 0 0;
  //   border-radius: 1rem;
  // }

  .insta-container {
    height: 100vh;
  }

  .video {
    object-fit: contain;
    background-repeat: none;
    background-image: url("https://getjohnnyjazz.com/resources/jj-hero.jpg");
    background-size: cover;
    // background-position: 30vw;
  }
}
